.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.form-helper-text {
    margin: 0;
    color: red;
}

.text-input-container {
    margin-top: 10px;
    margin-bottom: 10px;
    flex: 1;
}

.select-label {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
}

.home-container {
    display: flex;
    height: 100%;
}

.side-nav {
    width: 130px;
    margin-top: 10px;
}

.side-nav-img {
    height: 36px;
    width: 36px;
}

.home-item {
    background-color: #e3e3e3;
    display: flex;
    align-items: flex-start;
    height: 100%;
    padding: 10px 30px 30px 10px;
    flex-direction: column;
    justify-content: flex-start;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.popup-content {
    background: white;
    border-radius: 8px;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.input-container-row {
    display: flex;
    gap: 10px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
