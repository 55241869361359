.main {
    background-color: #e3e3e3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.form-login-container {
    flex-direction: column;
    width: 25%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}

.form-conta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-left: 30px;
    margin-right: 30px;
}

.link-criar-conta {
    text-decoration: underline;
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn-login {
    margin-top: 10px;
}

.label-login {
    margin-left: 20px;
}