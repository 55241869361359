.form-criar-conta-container {
    width: 250px;
    background-color: white;
    border-radius: 10px;
}

.img-back {
    margin-top: 10px;
    margin-left: 10px;
}

